.OnboardingSyncPage {
  width: 100%;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .OnboardingSyncPage {
    max-width: 680px;
  }
}
