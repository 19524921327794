.ScenarioTabs .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  padding: 4px 16px !important;
}

.ScenarioTabs .ant-tabs-top > .ant-tabs-nav {
  margin: 0 0 0 !important;
}

.ScenarioTabs .ant-tabs {
  margin-left: 8px;
  max-width: 450px;
}
