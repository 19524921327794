.OnboardingLayout {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  padding-bottom: 106px;
}
.OnboardingLayoutHeader {
  width: 100%;
  max-width: 1264px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 32px;
  margin: 40px 0px;
}

.OnboardingLayoutContent {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  width: 100%;
  max-width: 1264px;
  flex: 1 1 0%;
  padding: 0px 32px;
}

.OnboardingLayoutFlex {
  width: 100%;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  margin: 60px 20px;
}

.OnboardingLayout .OnboardingForm {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.OnboardingLayout h1 {
  text-align: center;
  margin: 0 auto 15px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.OnboardingLayout .animated {
  animation-name: fadeIn;
  animation-duration: 1s;
}

.OnboardingLayout .FormButton {
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 4px;
  height: 40px;
  font-size: 12px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: bold;
}

.OnboardingLayout label {
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.OnboardingLayout .ant-alert {
  margin-top: 20px;
}
