.RegisterPage {
  display: flex;
  background-color: white;
  min-height: 100vh;
}

.RegisterPage .LeftPanel {
  width: 50%;
  background-color: rgb(242, 244, 247);
  margin: 16px 0px 16px 16px;
  border-radius: 8px;
}
@media only screen and (max-width: 1139px) {
  .RegisterPage .LeftPanel {
    display: none;
  }
}

.RegisterPage .MainPanel {
  width: 50%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
}

@media only screen and (max-width: 1139px) {
  .RegisterPage .MainPanel {
    width: 100%;
    padding: 48px 40px;
  }
}

.RegisterPage .FormHeader {
  text-align: center;
  margin-bottom: 40px;
}

.RegisterPage .LogoContainer {
  margin-bottom: 48px;
}

.RegisterPage .CatchPhrase {
  white-space: pre-line;
  font-size: 34px;
  font-weight: 600;
  margin-bottom: 16px;
}
.RegisterPage .Description {
  white-space: pre-line;
  font-size: 16px;
  color: rgba(11, 37, 75, 0.7);
}
.RegisterPage .FormContainer {
  max-width: 400px;
  width: 100%;
}
.RegisterPage .FormFooter {
  margin-top: 40px;
}
.RegisterPage .FormFooterMobile {
  text-align: center;
  margin-top: 35px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
@media only screen and (min-width: 1139px) {
  .RegisterPage .FormFooterMobile {
    display: none;
  }
}

.RegisterPage .AlreadyRegisteredContainer {
  text-align: center;
}
.RegisterPage .AlreadyRegisteredText {
  white-space: pre-line;
  font-size: 16px;
  color: rgba(11, 37, 75, 0.7);
  margin-bottom: 4px;
}
.RegisterPage .AlreadyRegisteredAction {
  white-space: pre-line;
  font-size: 16px;
  font-weight: 500;
}
.RegisterPage .CustomerFeedbacks {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.RegisterPage .CustomerFeedbacksTop {
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 64px 64px 0px;
}

.RegisterPage .QuoteContainer {
  margin-bottom: 80px;
}
.RegisterPage .QuoteIcon {
  width: 32px;
  height: 23px;
  margin: 10px;
}
.RegisterPage .QuoteText {
  background: #ffe58f;
  font-size: 20px;
  padding: 18px;
  border-radius: 12px;
  font-weight: 500;
  line-height: 36px;
  white-space: pre-line;
}
.RegisterPage .QuoteAuthor {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 10px;
  flex: 0 1 auto;
}
.RegisterPage .QuoteAuthorDescription {
  margin-left: 16px;
  font-size: 16px;
  line-height: 30px;
}
.RegisterPage .CustomerLogos {
  margin-bottom: 24px;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1px;
  font-weight: 500;
  text-transform: uppercase;
  color: rgba(11, 37, 75, 0.5);
}
.RegisterPage .CustomerLogosContainer {
  height: 40px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
}
.RegisterPage .CustomerLogo:not(:last-child) {
  margin-right: 30px;
}

.RegisterPage .CustomerLogo {
  height: 39px;
}
.RegisterPage .AppPreviewContainer {
  margin-top: 64px;
  position: relative;
}
.RegisterPage .AppPreviewContainer img {
  width: 100%;
  max-width: 800px;
}

/* label, button, alert */

.RegisterPage label {
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.RegisterPage button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  border-radius: 4px;
  height: 40px;
  font-size: 12px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: bold;
}

.RegisterPage .ant-alert {
  margin-bottom: 20px;
}
