.BillingModal .ant-list-item {
  height: 100%;
}
.BillingModal .ant-input-number-input {
  text-align: center;
}
.BillingModal .ant-modal-content {
  background-color: rgb(240, 242, 245);
}
.BillingModal .ant-modal-header {
  background-color: rgb(240, 242, 245);
}
