svg.AddSubCategory {
  display: none;
  color: #ea6621;
  float: right;
  padding: 2%;
}

.ant-select-tree-title:hover svg.AddSubCategory {
  display: inline;
}

svg.AddSubCategory:hover {
  transform: scale(1.3);
}
