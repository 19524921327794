/* Header  */
#MainTable .ant-table thead > tr > th {
  position: sticky !important;
  top: 81px !important;
  background: #fafafa;
  z-index: 1;
}

#MainTable .ant-table-row-expand-icon {
  float: right;
}
#MainTable .ant-table-row-expand-icon-spaced {
  display: none;
}
#MainTable span.ant-table-row-indent.indent-level-1 {
  padding-left: 0 !important;
}
#MainTable span.ant-table-row-indent.indent-level-2 {
  padding-left: 16px !important;
}
#MainTable span.ant-table-row-indent.indent-level-3 {
  padding-left: 32px !important;
}
#MainTable span.ant-table-row-indent.indent-level-4 {
  padding-left: 48px !important;
}
#MainTable span.ant-table-row-indent.indent-level-5 {
  padding-left: 64px !important;
}

/* Table */
#MainTable .ant-table tbody > tr > td {
  padding: 12px 12px;
  font-size: 13px;
  color: rgb(30, 30, 30);
}
#MainTable.editable .ant-table tbody > tr > td {
  padding: 12px 8px;
}

#MainTable .ant-input-number {
  width: 100%;
}
#MainTable .ant-input-number-input {
  padding: 0 2px;
}
#MainTable .ant-table tbody > tr > td:not(:first-child) {
  text-align: right;
}
#MainTable .ant-table tbody > tr > td:first-child {
  text-align: left;
}
#MainTable .ant-table thead > tr > th.cell-is-present {
  border-left: 1px dashed rgb(92, 166, 239);
}
#MainTable .ant-table tbody > tr > td.cell-is-present {
  border-left: 1px dashed rgb(92, 166, 239);
}
#MainTable .ant-table .ant-progress-text {
  width: auto;
}
#MainTable .ant-table .ant-progress-line {
  margin: 0;
}
@media (max-width: 1280px) {
  #MainTable .ant-table tbody > tr > td {
    font-size: 11px;
  }
  #MainTable .ant-table tbody > tr > td .ant-input-number {
    font-size: 11px;
  }
}
/* HEADER ROW HOVER */
#MainTable .ant-table thead > tr > th:not(:first-child):hover {
  cursor: pointer;
}
/* ROW HOVER */
#MainTable .ant-table tbody > tr.ant-table-row:hover > td {
  background-color: rgb(223, 223, 223);
}
/* CELL HOVER */
/* #MainTable.ant-table tbody > tr.ant-table-row > td:hover {
  background-color: rgb(230, 230, 230);
} */
/* HEADER ROW */
#MainTable .ant-table thead > tr > th {
  background-color: rgb(244, 244, 244);
  text-align: center;
  user-select: none;
}
#MainTable .ant-table thead > tr > th:first-child {
  text-align: left;
}
#MainTable .ant-table thead > tr > th:first-child {
  padding: 0 24px;
}
#MainTable .ant-table thead > tr > th {
  border-top: 3px solid transparent;
}
#MainTable .ant-table thead > tr > th:not(:first-child):hover {
  border-top: 3px solid rgb(30, 136, 230);
}
#MainTable .ant-table thead > tr > th.cell-is-selected {
  border-top: 3px solid rgb(30, 136, 230);
}
/* CELL BORDER */
#MainTable .ant-table tbody > tr > td {
  border-color: rgb(223, 223, 223);
}
#MainTable .ant-table thead > tr > th.cell-is-selected + th {
  box-shadow: 5px 0 5px -5px inset;
}
#MainTable .ant-table thead > tr > th.cell-is-selected:before {
  box-shadow: -5px 0 5px -5px inset;
  content: " ";
  height: 100%;
  left: -5px;
  position: absolute;
  top: 24px;
  width: 5px;
}
#MainTable .ant-table tbody > tr > td.cell-is-selected {
  padding: 0px 12px;
}
#MainTable .ant-table tbody > tr > td.cell-is-selected + td {
  box-shadow: 5px 0 5px -5px inset;
}
#MainTable .ant-table tbody > tr > td.cell-is-selected:before {
  box-shadow: -5px 0 5px -5px inset;
  content: " ";
  height: 100%;
  left: -5px;
  position: absolute;
  top: 0;
  width: 5px;
}
/* SECTION ROW BACKGROUND */
#MainTable .ant-table tbody > tr.ant-table-row.ant-table-row-level-0 > td {
  background-color: rgb(237, 237, 237);
}

#MainTable .ant-table tbody > tr.ant-table-row > td {
  cursor: pointer;
}
#MainTable .ant-table tbody > tr.ant-table-row.row-is-balance > td,
#MainTable .ant-table tbody > tr.ant-table-row.row-is-variation > td,
#MainTable .ant-table tbody > tr.ant-table-row.row-is-separator > td,
#MainTable .ant-table tbody > tr.ant-table-row > td:first-child {
  cursor: auto;
}
#MainTable .ant-table tbody > tr.ant-table-row > td.is-parent-not-expanded {
  cursor: zoom-in;
}
#MainTable .ant-table tbody > tr.ant-table-row > td.is-parent-expanded {
  cursor: zoom-out;
}
#MainTable .ant-table tbody > tr.ant-table-row.row-is-parent > td {
  font-weight: bold;
  user-select: none;
}
#MainTable .ant-table tbody > tr.ant-table-row.row-is-cashIn > td {
  background-color: rgba(225, 246, 235);
}
#MainTable .ant-table tbody > tr.ant-table-row.row-is-cashOut > td {
  background-color: rgba(249, 221, 220);
}
#MainTable .ant-table tbody > tr.ant-table-row.row-is-balance > td {
  background-color: rgb(220, 220, 220);
}
#MainTable .ant-table tbody > tr.ant-table-row.row-is-separator > td {
  background-color: white;
  border-right: 0;
}
/* Firefox */
#MainTable input[type="number"] {
  -moz-appearance: textfield;
}
#MainTable input[type="number"] {
  text-align: right;
  unicode-bidi: bidi-override;
}
/* Chrome, Safari, Edge, Opera */
#MainTable input::-webkit-outer-spin-button,
#MainTable input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* DONT DISPLAY ARROW OF INPUT NUMBER */
#MainTable .input-number-budget .ant-input-number-handler-wrap {
  display: none;
}
/* REMOVE MARGIN BOTTOM OF INPUT NUMBER */
#MainTable .input-number .ant-form-item {
  margin: 0px;
}
/* REMOVE MARGIN BOTTOM OF INPUT NUMBER */
#MainTable .input-number {
  margin-top: -6px;
  margin-bottom: -6px;
}

/* DUPLICATE ICON */
#MainTable .input-number .duplicate-icon {
  display: inline-block;
  opacity: 0;
  position: absolute;
  top: 8px;
  right: 0px;
}
#MainTable.editable .ant-table tbody > tr > td .ant-form-item-control-input {
  margin-right: 8px;
}
#MainTable .ant-table-cell:hover .input-number .duplicate-icon {
  opacity: 1;
}
#MainTable .ant-table-cell:hover .input-number .duplicate-icon svg {
  margin: 8px 2px;
}
#MainTable .vat-icon:hover,
#MainTable .duplicate-icon:hover {
  color: #ea6621;
}
#MainTable .alternative-scenario-value input {
  text-decoration: underline;
}
