.UnloggedLayout {
  background-color: white;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  overflow: auto;
}
.UnloggedInner {
  max-width: 500px;
  width: 100%;
  align-self: center;
  padding: 32px 0px;
  margin: auto;
}

.UnloggedLayout .UnloggedHeader {
  margin-bottom: 48px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.UnloggedLayout .UnloggedLogo {
  height: auto;
  width: 100%;
  max-width: 150px;
}

.UnloggedLayout .UnloggedForm {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.form-container {
  /* this property has an interesting behaviour */
  /* margin: 0 auto; */
  color: #5e6c84;
}

.form-container h1 {
  font-size: 26px;
  text-align: center;
  font-weight: normal;
  margin: 0 auto 15px auto;
  color: #162a4e;
}

.form-container h1 + p {
  font-size: 16px;
  text-align: center;
  color: #5e6c84;
  margin: 0 auto;
}

.form-container > form {
  margin-top: 30px;
}

.form-container > form ~ p {
  margin-top: 40px;
  text-align: center;
  color: #5e6c84;
  font-size: 14px;
}

.UnloggedLayout .FormButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 40px;
  font-size: 12px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: bold;
}

.UnloggedLayout label {
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.UnloggedLayout .animated {
  animation-name: fadeIn;
  animation-duration: 1s;
}

.UnloggedLayout .ant-alert {
  margin-top: 20px;
}
