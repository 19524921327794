.GeneralLayout .Logo {
  height: 32px;
  margin: 16px 24px;
}

.GeneralLayout .ant-layout-sider {
}
.GeneralLayout .ant-layout-sider .ant-menu-light {
}

.GeneralLayout .ant-layout-sider .ant-menu-item-selected {
}
.GeneralLayout .MenuContainer .ant-dropdown-menu-item-selected {
  color: black !important;
}

.GeneralLayout .ant-layout,
.GeneralLayout .ant-layout-footer {
  background: white;
}

.GeneralLayout .ContentHeader {
  height: 96px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  padding: 0px 32px;
  margin: 0px auto -32px;
}

.GeneralLayout .ContentBody {
  padding: 32px;
}

.GeneralLayout .ContentHeader h4 {
  margin-bottom: 0;
}

.GeneralLayout .MenuContainer ul.ant-menu li.ant-menu-item {
  padding: 0 16px;
  font-size: 13px;
}

.GeneralLayout .MenuContainer .ant-dropdown-menu-item {
  padding: 10px 14px;
}

.GeneralLayout .MenuContainer {
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.09), 0 2px 8px 0 rgba(0, 0, 0, 0.04);
}

.GeneralLayout .ant-avatar:hover {
  opacity: 0.7;
}

.GeneralLayout .BottomMenu ul.ant-menu li:last-child {
  padding: 0 20px;
}
